import './OrderDescriptionEditMobileModal.css';

import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import MobileModal from '../MobileModal/MobileModal';

import { saveUserInfo } from '../../_reducers/user';
import { updateManagerOrderInfo } from '../../functions/user';

export default function OrderDescriptionEditMobileModal(props) {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);  

    const [description, setDescription] = useState(props.description);
    const [isInitialized, setInitialized] = useState(false);

    const initializeInput = () => {
        setDescription(props.description);
    }

    if (!isInitialized & !description && props.description) {
        initializeInput();
        setInitialized(true);
    }

    const editButtonClickEvent = () => {
        let managerOrderInfo = user.managerOrderInfo;
        managerOrderInfo.description = description;
        updateManagerOrderInfo(managerOrderInfo).then((user) => {
            dispatch(saveUserInfo(user));
        });
        props.checkButtonEvent(description);
    }

    let isEditable = (description);

    const closeEvent = () => {
        props.closeEvent();
        initializeInput();
    }

    const updateEvent = () => {
        editButtonClickEvent();
        props.checkButtonEvent(description);
        props.closeEvent();
    }

    return (
        <MobileModal isOn={props.isOn} 
                     closeEvent={closeEvent} 
                     isCompleteButtonNeeded={isEditable}
                     completeEvent={updateEvent}
                     completeText='수정하기'>
            <div className='item-description-edit-mobile-modal'>
                <span className='item-description-edit-mobile-modal-title'>주문 상세</span>
                <textarea className='item-description-edit-mobile-modal-textarea' 
                    placeholder='주문 상세를 입력해주세요.'
                    value={description} 
                    onChange={(e) => setDescription(e.target.value)}></textarea>

                <div style={{'minHeight':'20px'}}></div>
            </div>
        </MobileModal>
    )
}

const defaultClickEvent = () => {

}

OrderDescriptionEditMobileModal.defaultProps = {
    isOn: false,
    description: '주문 상세를 입력해주세요.',
    checkButtonEvent: defaultClickEvent,
}