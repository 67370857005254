export const SAVE_USER_INFO = "USER/SAVE_USER_INFO";
export const LOGIN = "USER/LOGIN";
export const LOGOUT = "USER/LOGOUT";
export const SET_MANAGER_ORDER = "USER/SET_MANAGER_ORDER";
export const RESET_MANAGER_ORDER = "USER/RESET_MANAGER_ORDER";
export const SAVE_MANAGER_ORDER_INFO = "USER/SAVE_MANAGER_ORDER_INFO";
export const SET_MANAGER_ORDER_PAYED = "USER/SET_MANAGER_ORDER_PAYED";
export const RESET_MANAGER_ORDER_PAYED = "USER/RESET_MANAGER_ORDER_PAYED";
export const SET_MANAGER_ORDER_PAY_METHOD = "USER/SET_MANAGER_ORDER_PAY_METHOD";
export const SET_MANAGER_ORDER_ORDER_METHOD = "USER/SET_MANAGER_ORDER_ORDER_METHOD";
export const SET_MANAGER_ORDER_DESCRIPTION = "USER/SET_MANAGER_ORDER_DESCRIPTION";

export const saveUserInfo = (info) => ({type:SAVE_USER_INFO, info:info});
export const login = () => ({type:LOGIN});
export const logout = () => ({type:LOGOUT});
export const setManagerOrder = () => ({type:SET_MANAGER_ORDER});
export const resetManagerOrder = () => ({type:RESET_MANAGER_ORDER});
export const saveManagerOrderInfo = (info) => ({type:SAVE_MANAGER_ORDER_INFO, info:info});
export const setManagerOrderPayed = () => ({type:SET_MANAGER_ORDER_PAYED});
export const resetManagerOrderPayed = () => ({type:RESET_MANAGER_ORDER_PAYED});
export const setManagerOrderPayMethod = (info) => ({type:SET_MANAGER_ORDER_PAY_METHOD, info});
export const setManagerOrderOrderMethod = (info) => ({type:SET_MANAGER_ORDER_ORDER_METHOD, info});
export const setManagerOrderDescription = (info) => ({type:SET_MANAGER_ORDER_DESCRIPTION, info});

const initialState = {
    isLogined: false,
    name: "이름",
    email: "abcd@gmail.com",
    phone: "010-0000-0000",
    class: 0,
    socialId: "abcdabcd",
    shippingIndex: 0,
    shippings: [],
    orders: [],
    deliveryCart: [],
    pickupCart: [],
    presentCart: [],
    likes: [],
    coupons: [],
    id: '000',
    date: '2023-01-01',
    time: '12:00',
    isManagerOrder: false,
    managerOrderInfo: {
        name: "차은우",
        phone: "010-0000-0000",
        isPayed: false,
        isManagerOrder: true,
        payMethod: '미결제',
        orderMethod: 'DM',
        description: '',
    }
}

const user = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_USER_INFO:
            return {
                ...state,
                name: action.info.name,
                email: action.info.email,
                phone: action.info.phone,
                class: action.info.class,
                socialId: action.info.socialId,
                shippings: action.info.shippings,
                orders: action.info.orders.sort(function(a,b) {
                    return (a.createdAt < b.createdAt ? 1: -1)
                }),
                likes: action.info.likes,
                deliveryCart: action.info.deliveryCart,
                pickupCart: action.info.pickupCart,
                presentCart: action.info.presentCart,
                shippingIndex: action.info.shippingIndex,
                id: action.info._id,
                date: action.info.date,
                time: action.info.time,
                managerOrderInfo: action.info.managerOrderInfo,
                isManagerOrder: false,
            }
        case SAVE_MANAGER_ORDER_INFO:
            return {
                ...state,
                managerOrderInfo: {
                    ...state.managerOrderInfo,
                    name: action.info.name,
                    phone: action.info.phone,
                }
            }
        case LOGIN:
            return {
                ...state,
                isLogined: true,
            }
        case LOGOUT:
            return {
                isLogined: false,
            }
        case SET_MANAGER_ORDER:
            return {
                ...state,
                isManagerOrder: true,
            }
        case RESET_MANAGER_ORDER:
            return {
                ...state,
                isManagerOrder: false,
            }
        case SET_MANAGER_ORDER_PAYED:
            return {
                ...state,
                managerOrderInfo: {
                    ...state.managerOrderInfo,
                    isPayed: true,
                }
            }
        case RESET_MANAGER_ORDER_PAYED:
            return {
                ...state,
                managerOrderInfo: {
                    ...state.managerOrderInfo,
                    isPayed: false,
                }
            }
        case SET_MANAGER_ORDER_PAY_METHOD:
            if (action.info.payMethod !== '미결제') {
                return {
                    ...state,
                    managerOrderInfo: {
                        ...state.managerOrderInfo,
                        isPayed: true,
                        payMethod: action.info.payMethod,
                    }
                }
            } else {
                return {
                    ...state,
                    managerOrderInfo: {
                        ...state.managerOrderInfo,
                        isPayed: false,
                        payMethod: action.info.payMethod,
                    }
                }
            }
            
        case SET_MANAGER_ORDER_ORDER_METHOD:
            return {
                ...state,
                managerOrderInfo: {
                    ...state.managerOrderInfo,
                    orderMethod: action.info.orderMethod,
                }
            }
        case SET_MANAGER_ORDER_DESCRIPTION:
            return {
                ...state,
                managerOrderInfo: {
                    ...state.managerOrderInfo,
                    description: action.info.description,
                }
            }
        default:
            return state;
    }
};

export default user;