import './UnderlineNavContainer.css';

import { useState } from 'react';

import UnderlineNavButton from '../UnderlineNavButton/UnderlineNavButton';

export default function UnderlineNavContainer(props) {

    let navButtonEvent;
    const UnderlineNavButtons = props.navTextList.map((navText,index) => {
        navButtonEvent = () => {
            window.location.href = props.linkList[index];
        }
        let cart;
        switch(navText) {
            case '픽업주문':
                cart = props.cart.pickupCart;
                break;
            case '선물주문':
                cart = props.cart.presentCart;
                break;
            case '배송주문':
                cart = props.cart.deliveryCart;
                break;
            default:
                cart = props.cart.pickupCart;
                break;
        }
        let cartLenght;
        if (cart) {
            cartLenght = cart.length;
        } else {
            cartLenght = 0;
        }
        return (
            <UnderlineNavButton
                text={navText}
                isOn={navText === props.status}
                clickEvent={navButtonEvent}
                key={'underline-nav-'+index}
                isSuperscriptNeeded={true}
                superscript={cartLenght}/>
        )
    })

    return (
        <div className='underline-nav-container'>
            {UnderlineNavButtons}
        </div>
    )
}

UnderlineNavContainer.defaultProps = {
    status: 'nav1',
    navTextList: ['nav1','nav2','nav3'],
    linkList: ['nav1','nav2','nav3'],
    cart: {
        'deliveryCart': [],
        'pickupCart': [],
        'presentCart': [],
    },
}

