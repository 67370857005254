import '../default.css';

import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import Header from "../components/Header/Header";
import OrderProcessing from '../components/OrderProcessing/OrderProcessing';

import { authUser } from '../functions/auth';
import { saveUserInfo, login, logout } from '../_reducers/user';
import { managerOrder } from '../functions/order';

export default function ManagerOrderRequestView() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(state => state.user);

    let cartItemNumber;
    if (user.deliveryCart && user.pickupCart && user.presentCart) {
        cartItemNumber = user.deliveryCart.length+user.pickupCart.length+user.presentCart.length;
    } else {
        cartItemNumber = 0;
    }

    useEffect(() => {
            authUser().then((user) => {
                if (user) {
                    dispatch(login());
                    dispatch(saveUserInfo(user));
                } else {
                    dispatch(logout());
                    navigate('/');
                }
                managerOrder({
                    'managerOrderInfo': user.managerOrderInfo,
                    'type': window.location.pathname.split('/')[4],
                }).then((res) => {
                    console.log(res);
                    window.location.href = "/order/manager/complete/"+res.data.order._id;
                })
            })
    }, []);

    return (
        <div className="page">
            <Header isLogined={user.isLogined} name={user.name} cartItemNumber={cartItemNumber} userClass={user.class}/>
            <div className='contents-container'>
                <div className='contents'>
                    <OrderProcessing/>
                </div>
            </div>
        </div>
    )
}