import './ItemDescription.css';

import { useState } from 'react';

import IconButton from '../IconButton/IconButton';
import ItemDescriptionEditModal from '../ItemDescriptionEditModal/ItemDescriptionEditModal';
import ItemDescriptionEditMobileModal from '../ItemDescriptionEditMobileModal/ItemDescriptionEditMobileModal';

export default function ItemDescription(props) {
    const [isDescriptionMobileModalOn, setDescriptionMobileModalOn] = useState(false);

    const switchDescriptionMobileModalDispaly = () => {
        if (isDescriptionMobileModalOn) {
            setDescriptionMobileModalOn(false);
        } else {
            setDescriptionMobileModalOn(true);
        }
    }
        
    let Gradients;
    let GradientsContainer;
    if (props.gradientList) {
        if (props.gradientList.length > 0) {
            Gradients = props.gradientList.map((gradient, index) => {
                return (
                    <div className='item-description-gradients-container' key={'gradient_'+index}>
                        <span className='item-description-text name'>
                            {gradient.name}
                        </span>
                        <span className='item-description-text'>
                            식품유형: {gradient.type}
                        </span>
                        <span className='item-description-text'>
                            소재지: {gradient.origin}
                        </span>
                        <span className='item-description-text'>
                            제조 년월일: {gradient.productionDate}
                        </span>
                        <span className='item-description-text'>
                            유통기한: {gradient.expirationDate}
                        </span>
                        <span className='item-description-text'>
                            내용량: {gradient.weight}
                        </span>
                        <span className='item-description-text'>
                            포장재: {gradient.package}
                        </span>
                        <span className='item-description-text'>
                            원재료명 및 함량: {gradient.gradients}
                        </span>
                    </div>
                )
            })
            GradientsContainer =
                <div className='item-description-container'>
                    <div className='item-description-title-container'>
                        <h2 className='item-description-title'>재료 및 성분</h2>
                    </div>
                    <div className='item-description-text-container'>
                        {Gradients}
                    </div>
                </div>
        }
    }
    
    let Image2Container;
    if (props.image2) {
        Image2Container =
            <img className='item-description-image image2'
                    src={props.image2}
                    alt='item2'></img>
    }

    let Image3Container;
    if (props.image3) {
        Image3Container =
            <img className='item-description-image image3'
                src={props.image3}
                alt='item3'></img>
    }

    const showItemDescriptionEditModal = () => {
        if (window.innerWidth > 700) {
            const ItemDescriptionEditModal = document.getElementById('item-description-edit-modal-'+props.id);
            ItemDescriptionEditModal.showModal();
        } else {
            switchDescriptionMobileModalDispaly();
        }
    }

    let DescriptionEditButton;
    if ((props.userClass === 99) || (props.userClass === 'manager')) {
        DescriptionEditButton =
            <IconButton
                altText='edit'
                backgroundColor='rgba(0,0,0,0)'
                image={require('../../icons/edit_grey.png')}
                clickEvent={showItemDescriptionEditModal}/>
    }

    let image1;
    if (!props.image1) {
        image1 = 
            <img className='item-description-image'
                 src={require('../../images/question.png')}
                 alt='item1'></img>
    } else {
        image1 = 
            <img className='item-description-image'
                 src={props.image1}
                 alt='item1'></img>
    }


    return (
        <div className='item-description'>
            {image1}
            <div className='item-description-container name'>
                <div className='item-description-title-container name'>
                    <h2 className='item-description-title'>{props.name}</h2>
                    {DescriptionEditButton}
                </div>
                <div className='item-description-text-container'>
                    <span className='item-description-text extended-height'>
                        {props.description}
                    </span>
                </div>
            </div>
            {Image2Container}
            {Image3Container}
            <div className='item-description-container'>
                <div className='item-description-title-container'>
                    <h2 className='item-description-title'>맛있게 먹는 법</h2>
                </div>
                <div className='item-description-text-container'>
                    <span className='item-description-text'>
                        배송 받으신 후 바로, 또는 그 다음 날까지 먹어야 가장 맛있어요.
                    </span>
                    <span className='item-description-text'>
                        3일 이상 보관 후 드실 분들은 냉동실에 보관 후 해동해서 드세요.
                    </span>
                </div>
            </div>
            {GradientsContainer}
            <div className='item-description-container'>
                <div className='item-description-title-container'>
                    <h2 className='item-description-title'>취소 및 환불 규정</h2>
                </div>
                <div className='item-description-text-container'>
                    <span className='item-description-text'>
                        주문 후 제작되는 식품으로, '상품 준비 중' 상태에서는 취소가 불가하니, 신중한 구매 부탁드립니다.
                    </span>
                    <span className='item-description-text'>
                        제품을 받아보신 후 이상이 있을 시, 식품이기 때문에 배송 완료 후 최대 1일 이내에 연락 주시기 바랍니다.
                    </span>
                    <span className='item-description-text'>
                        크럼블이 들어간 제품은 개별 포장을 하더라도 봉지 안에 부스러기가 떨어질 수 있으므로 양해부탁드립니다.
                    </span>
                    <span className='item-description-text'>
                        선물박스는 서비스로 제공되는 상품으로, 선물박스 파손으로 인한 교환, 환불은 불가능합니다.
                    </span>
                    <span className='item-description-text'>
                        반품주소 : 경기도 고양시 덕양구 원흥1로 50 1층 마딘베이커리
                    </span>
                </div>
            </div>
            <div className='item-description-container'>
                <div className='item-description-title-container'>
                    <h2 className='item-description-title'>판매자 정보</h2>
                </div>
                <div className='item-description-text-container'>
                    <span className='item-description-text'>
                        브랜드: 마딘베이커리
                    </span>
                    <span className='item-description-text'>
                        원산지: 국산(경기도 고양시) 등
                    </span>
                    <span className='item-description-text'>
                        영수증 발급: 신용카드 전표, 현금영수증 발급
                    </span> 
                    <span className='item-description-text'>
                        취소 및 환불 안내: 0507-1356-4770
                    </span>
                </div>
            </div>
            <ItemDescriptionEditModal
                id={props.id}
                description={props.description}/>
            <ItemDescriptionEditMobileModal
                isOn={isDescriptionMobileModalOn}
                id={props.id}
                description={props.description}
                closeEvent={switchDescriptionMobileModalDispaly}/>
        </div>
    )
}

ItemDescription.defaultProps = {
    name: '상품명',
    description: '상품의 소개를 작성해주세요.',
    image1: '',
    image2: '',
    image3: '',
    gradientList: [
        {name: '상품명을 적어주세요.',
         type: '상품 종류를 적어주세요.',
         place: '생산지를 적어주세요.',
         productionDate: '생신연월일을 적어주세요.',
         expirationDate: '유통기한을 적어주세요.',
         weight: '무게를 적어주세요.',
         package: '포장재를 적어주세요.',
         gradients: '밀가루(미국산),버터(프랑스산),계란(국내산)'},
        {name: '상품명을 적어주세요.',
         type: '상품 종류를 적어주세요.',
         place: '생산지를 적어주세요.',
         productionDate: '생신연월일을 적어주세요.',
         expirationDate: '유통기한을 적어주세요.',
         weight: '무게를 적어주세요.',
         package: '포장재를 적어주세요.',
         gradients: '밀가루(미국산),버터(프랑스산),계란(국내산)'}
    ],
    userClass: 0,
}