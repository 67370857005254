import './PayMethod.css';

import { useDispatch, useSelector } from 'react-redux';

import { saveUserInfo } from '../../_reducers/user';
import { updateManagerOrderInfo } from '../../functions/user';

export default function PayMethod(props) {
    const user = useSelector(state => state.user);
    
    return (
        <div className='pay-method'>
            <span className='pay-method-title'>결제</span>
            <div className='pay-method-buttons'>
                <PayMethodButton text='미결제' isOn={user.managerOrderInfo.payMethod === '미결제'}/>
                <PayMethodButton text='신용카드' isOn={user.managerOrderInfo.payMethod === '신용카드'}/>
                <PayMethodButton text='계좌이체' isOn={user.managerOrderInfo.payMethod === '계좌이체'}/>
            </div>
        </div>
    )
}

function PayMethodButton(props) {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);

    const payMethodButtonClickEvent = (payMethod) => {
        if (payMethod === '미결제') {
            let managerOrderInfo = user.managerOrderInfo;
            managerOrderInfo.payMethod = payMethod;
            managerOrderInfo.isPayed = false;
            updateManagerOrderInfo(managerOrderInfo).then((user) => {
                dispatch(saveUserInfo(user));
            });
        } else {
            let managerOrderInfo = user.managerOrderInfo;
            managerOrderInfo.payMethod = payMethod;
            managerOrderInfo.isPayed = true;
            updateManagerOrderInfo(managerOrderInfo).then((user) => {
                dispatch(saveUserInfo(user));
            });
        }
    }

    if (props.isOn) {
        return (
            <button className='pay-method-button on'>
                <span className='pay-method-button-text on'>{props.text}</span>
            </button>
        )
    } else {
        return (
            <button className='pay-method-button off' onClick={() => payMethodButtonClickEvent(props.text)}>
                <span className='pay-method-button-text off'>{props.text}</span>
            </button>
        )
    }
}

PayMethod.defaultProps = {

}

PayMethodButton.defaultProps = {
    isOn: false,
    text: "버튼",
}